.nav-item {
  border-radius: 12px;

  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem;
}

.nav-item > a {
  text-decoration: none;
  font-family: "Space Grotesk";
  color: var(--black);
}

.nav-list {
  list-style: none;
}

.nav-item {
  height: 28px;
  padding-top: 10px;
  padding-left: 10px;
  width: 155px;
}

.nav-item:hover {
  background-color: var(--community-light);
  cursor: pointer;
}

.nav-item:hover > a {
  font-weight: 600;
}

.nav {
  padding: 0.5rem;
}

.nav-container {
  border-right: 1px solid var(--lightest-black);

  height: 100%;
}

.layout-container {
  height: 100%;
  display: flex;
}
